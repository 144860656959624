import React from "react";
import Navbar from "./components/Navbar";
import "./i18n";
import images from "./images/images.ts";
import config from "./config.json";
import { useTranslation } from "react-i18next";
function App() {
  const { t } = useTranslation();
  const teamList = [
    {
      title: t("team_member_title_01"),
      image: images.team_01,
      name: t("team_member_name_01"),
      desc: t("team_member_desc_01"),
    },
    {
      title: t("team_member_title_02"),
      image: images.team_02,
      name: t("team_member_name_02"),
      desc: t("team_member_desc_02"),
    },
    {
      title: t("team_member_title_03"),
      image: images.team_03,
      name: t("team_member_name_03"),
      desc: t("team_member_desc_03"),
    },
    {
      title: t("team_member_title_04"),
      image: images.team_04,
      name: t("team_member_name_04"),
      desc: t("team_member_desc_04"),
    },
    {
      title: t("team_member_title_05"),
      image: images.team_05,
      name: t("team_member_name_05"),
      desc: t("team_member_desc_05"),
    },
    {
      title: t("team_member_title_06"),
      image: images.team_06,
      name: t("team_member_name_06"),
      desc: t("team_member_desc_06"),
    },
    {
      title: t("team_member_title_07"),
      image: images.team_07,
      name: t("team_member_name_07"),
      desc: t("team_member_desc_07"),
    },
  ];

  return (
    <div className="App">
      <Navbar />
      <div
        className="main_wrap"
        style={{ width: "100%", display: "flex", justifyContent: "center" }}
      >
        <div className="title_wrap">
          <div className="title B40_B">{t("title")}</div>
        </div>
      </div>
      <section id="about" className="section">
        <h1 className="B40_B">{t("about_title")}</h1>
        <div style={{ display: "flex" }}>
          <div style={{ marginRight: 25 }}>
            <div className="about_item about_back_01">
              <img src={images.about_icon_01} />
              <div className="about_item_label">{t("about_01")}</div>
            </div>
            <div className="about_item about_back_02">
              <img src={images.about_icon_03} />
              <div className="about_item_label gray850">{t("about_02")}</div>
            </div>
            <div className="about_item about_back_01">
              <img src={images.about_icon_05} />
              <div className="about_item_label ">{t("about_03")}</div>
            </div>
          </div>
          <div style={{ marginTop: 20 }}>
            <div className="about_item about_back_02">
              <img src={images.about_icon_02} />
              <div className="about_item_label gray850">{t("about_04")}</div>
            </div>
            <div className="about_item about_back_01">
              <img src={images.about_icon_04} />
              <div className="about_item_label ">{t("about_05")}</div>
            </div>
            <div className="about_item about_back_02">
              <img src={images.about_icon_06} />
              <div className="about_item_label gray850">{t("about_06")}</div>
            </div>
            <div className="about_item about_back_01">
              <img src={images.about_icon_07} />
              <div className="about_item_label ">{t("about_07")}</div>
            </div>
          </div>
        </div>
      </section>
      <section id="token" className="section gray850">
        <h1 className="B40_B">{t("token_title")}</h1>
        <div className="token_wrap">
          <div className="token_main_wrap">
            <div className="B14_DB">{t("token_tokenomics")}</div>
            <img src={images.tokennomics} />
          </div>
          <div className="token_card_wrap">
            <div className="token_card">
              <div className="token_card_title B14_DB">
                {t("token_left_title")}
              </div>
              <div className="token_card_item">
                <div className="token_card_item_seq">1</div>
                <div className="token_card_item_desc B14_M">
                  {t("token_left_01")}
                </div>
              </div>
              <div className="token_card_item">
                <div className="token_card_item_seq">2</div>
                <div className="token_card_item_desc B14_M">
                  {t("token_left_02")}
                </div>
              </div>
              <div className="token_card_item">
                <div className="token_card_item_seq">3</div>
                <div className="token_card_item_desc B14_M">
                  {t("token_left_03")}
                </div>
              </div>
              <div className="token_card_item">
                <div className="token_card_item_seq">4</div>
                <div className="token_card_item_desc B14_M">
                  {t("token_left_04")}
                </div>
              </div>
            </div>
            <div className="token_card">
              <div className="token_card_title B14_DB">
                {t("token_right_title")}
              </div>
              <div className="token_card_item">
                <div className="token_card_item_seq">1</div>
                <div className="token_card_item_desc B14_M">
                  {t("token_right_01")}
                </div>
              </div>
              <div className="token_card_item">
                <div className="token_card_item_seq">2</div>
                <div className="token_card_item_desc B14_M">
                  {t("token_right_02")}
                </div>
              </div>
              <div className="token_card_item">
                <div className="token_card_item_seq">3</div>
                <div className="token_card_item_desc B14_M">
                  {t("token_right_03")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="team" className="section ">
        <div
          style={{
            maxWidth: 1280,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h1 className="B40_B">{t("team_title_01")}</h1>
          <div className="B18_DB">{t("team_title_02")}</div>
          <div className="team_title B18_DB">{t("team_title_03")}</div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              rowGap: 40,
              columnGap: 24,
              justifyContent: "center",
            }}
          >
            {teamList.map((itm, idx) => (
              <div key={idx} className="team_card">
                <div className="team_card_title B12_DB">{itm.title}</div>
                <img src={itm.image} />
                <div className="team_card_name B14_B">{itm.name}</div>
                <div className="team_card_desc B14_M">{itm.desc}</div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section id="vision" className="section">
        <h1 className="B40_B">{t("vision_title")}</h1>
        <div className="B16_M white">{t("vision_sub_title")}</div>
        <ul className="vision_content">
          <li className="B14_M white">{t("vision_list_01")}</li>
          <li className="B14_M white">{t("vision_list_02")}</li>
          <li className="B14_M white">{t("vision_list_03")}</li>
          <li className="B14_M white">{t("vision_list_04")}</li>
          <li className="B14_M white">{t("vision_list_05")}</li>
        </ul>
      </section>
      <section id="download" className="section">
        <h1 className="B22_B" style={{ marginTop: 40 }}>
          {t("download_title")}
        </h1>
        <img src={images.download_logo} />
        <div className="version_text B14_B">{`${t("download_version")} ${
          config.version
        }`}</div>
        <a
          href="/api/file/apk"
          download="chatt-app.apk"
          className="download_button B16_DB"
        >
          {t("download_button")}
        </a>
      </section>
      <section id="link" className="section">
        <div
          style={{
            maxWidth: 640,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h1 className="B22_B" style={{ marginTop: 40 }}>
            {t("link_title")}
          </h1>
          <h2 className="link_item_title B13_M">🔗 Site</h2>
          <div className="link_item_wrap">
            <div>WebSite</div>
            <div
              className="link_item_link"
              onClick={() => {
                window.open("https://chainletter.site");
              }}
            >
              <div>https://chainletter.site</div>
              <img src={images.forward} />
            </div>
          </div>
          <div className="link_item_wrap">
            <div>Docs</div>
            <div
              className="link_item_link"
              onClick={() => {
                window.open("https://chatt.gitbook.io/chain_letter_docs");
              }}
            >
              <div> https://chatt.gitbook.io/chain_letter_docs</div>
              <img src={images.forward} />
            </div>
          </div>
          <h2 className="link_item_title B13_M">🔗 SNS</h2>
          <div className="link_item_wrap">
            <div>X</div>
            <div
              className="link_item_link"
              onClick={() => {
                window.open("https://x.com/chatt_guide");
              }}
            >
              <div> https://x.com/chatt_guide</div>
              <img src={images.forward} />
            </div>
          </div>
          <div className="link_item_wrap">
            <div>Instagram</div>
            <div
              className="link_item_link"
              onClick={() => {
                window.open("https://www.instagram.com/chatt.guide");
              }}
            >
              <div>https://www.instagram.com/chatt.guide</div>
              <img src={images.forward} />
            </div>
          </div>
          <div className="link_item_wrap">
            <div>Threads</div>
            <div
              className="link_item_link"
              onClick={() => {
                window.open("https://www.threads.net/@chatt.guide");
              }}
            >
              <div>https://www.threads.net/@chatt.guide</div>
              <img src={images.forward} />
            </div>
          </div>
          <h2 className="link_item_title B13_M">🔗 Community</h2>
          <div className="link_item_wrap">
            <div>Telegram Announcement</div>
            <div
              className="link_item_link"
              onClick={() => {
                window.open("https://t.me/chatt_guide");
              }}
            >
              <div>https://t.me/chatt_guide</div>
              <img src={images.forward} />
            </div>
          </div>
          <div className="link_item_wrap">
            <div>Telegram Community</div>
            <div
              className="link_item_link"
              onClick={() => {
                window.open("https://t.me/chatt_Community");
              }}
            >
              <div>https://t.me/chatt_Community</div>
              <img src={images.forward} />
            </div>
          </div>
          <h2 className="link_item_title B13_M">🔗 Data Room</h2>{" "}
          <div className="link_item_wrap">
            <div>Marketing Materials</div>
            <div
              className="link_item_link"
              onClick={() => {
                window.open(
                  "https://drive.google.com/drive/folders/1daRameS1K8kn7QXmav7mqmBkSuVHC_4E?usp=sharing"
                );
              }}
            >
              <div>
                https://drive.google.com/drive/folders/1daRameS1K8kn7QXmav7mqmBkSuVHC_4E?usp=sharing
              </div>
              <img src={images.forward} />
            </div>
          </div>
          <div className="link_item_wrap">
            <div>Rule</div>
            <div
              className="link_item_link"
              onClick={() => {
                window.open(
                  "https://drive.google.com/drive/folders/132OJXVzq3LHOum2efCYuLP4ivw4t2Qeu?usp=sharing"
                );
              }}
            >
              <div>
                https://drive.google.com/drive/folders/132OJXVzq3LHOum2efCYuLP4ivw4t2Qeu?usp=sharing
              </div>
              <img src={images.forward} />
            </div>
          </div>
          <div className="link_item_wrap">
            <div>FAQ</div>
            <div
              className="link_item_link"
              onClick={() => {
                window.open(
                  "https://drive.google.com/drive/folders/14deTMcqbc1sD3HXStVVcFhXdGD_GVK2k?usp=sharing"
                );
              }}
            >
              <div>
                https://drive.google.com/drive/folders/14deTMcqbc1sD3HXStVVcFhXdGD_GVK2k?usp=sharing
              </div>
              <img src={images.forward} />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default App;
