export default {
  dropdown_narrow: require("./dropdown_narrow.png"),
  logo: require("./logo.png"),
  ic_lan_chinese: require("./ic_lan_chinese.png"),
  ic_lan_english: require("./ic_lan_english.png"),
  ic_lan_japanese: require("./ic_lan_japanese.png"),
  ic_lan_korean: require("./ic_lan_korean.png"),
  background: require("./background.png"),
  about_back_01: require("./about_back_01.png"),
  about_back_02: require("./about_back_02.png"),
  about_icon_01: require("./about_icon_01.png"),
  about_icon_02: require("./about_icon_02.png"),
  about_icon_03: require("./about_icon_03.png"),
  about_icon_04: require("./about_icon_04.png"),
  about_icon_05: require("./about_icon_05.png"),
  about_icon_06: require("./about_icon_06.png"),
  about_icon_07: require("./about_icon_07.png"),
  team_01: require("./team_01.png"),
  team_02: require("./team_02.png"),
  team_03: require("./team_03.png"),
  team_04: require("./team_04.png"),
  team_05: require("./team_05.png"),
  team_06: require("./team_06.png"),
  team_07: require("./team_07.png"),
  download_logo: require("./download_logo.png"),
  forward: require("./forward.png"),
  tokennomics: require("./tokennomics.png"),
  menu: require("./menu.png"),
};
