import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import langEn from "./en"; // 영문 파일 import
import langKo from "./ko"; // 국문 파일 import
import langCn from "./cn"; // 국문 파일 import
import langJp from "./jp"; // 국문 파일 import

const resources = {
  en: {
    translation: langEn,
  },
  ko: {
    translation: langKo,
  },
  cn: {
    translation: langCn,
  },
  jp: {
    translation: langJp,
  },
};
i18n.use(initReactI18next).init({
  resources,
  lng: "en", // 기본 언어 설정
  debug: false, // 디버그 필요할 경우 true 변경
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
  react: {
    bindI18n: "languageChanged",
    bindI18nStore: "",
    transEmptyNodeValue: "",
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ["br", "strong", "i"],
    useSuspense: true,
  },
});

export default i18n;
