import React, { useState } from "react";
import "./Navbar.css";
import { FaBars, FaTimes } from "react-icons/fa";
import logo from "../images/logo.png";
import images from "../images/images.ts";
import { useTranslation } from "react-i18next";

const languageData = [
  {
    key: "en",
    title: "ENG",
    icon: images.ic_lan_english,
  },
  {
    key: "ko",
    title: "한국어",
    icon: images.ic_lan_korean,
  },
  {
    key: "jp",
    title: "日本語",
    icon: images.ic_lan_japanese,
  },
  {
    key: "cn",
    title: "中文",
    icon: images.ic_lan_chinese,
  },
];

const Navbar = () => {
  const [isMobile, setIsMobile] = useState(false);
  const { i18n, t } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const [currentValue, setCurrentValue] = useState("ENG");
  const [showOptions, setShowOptions] = useState(false);

  const handleOnChangeSelectValue = ({ key, title }) => {
    setCurrentValue(title);
    i18n.changeLanguage(key);
  };

  return (
    <div className="nav_wrap">
      <nav className="navbar">
        <div
          style={{ display: "flex", alignItems: "center" }}
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        >
          <img src={logo} style={{ width: 28, height: 28, marginRight: 8 }} />
          <sapn className="B16_DB">Chain Letter</sapn>
        </div>
        <ul
          className={isMobile ? "nav-links-mobile" : "nav-links"}
          onClick={() => setIsMobile(false)}
        >
          <li onClick={() => (window.location.href = "#about")}>
            <a>{t("nav_01")}</a>
          </li>
          <li onClick={() => (window.location.href = "#token")}>
            <a>{t("nav_02")}</a>
          </li>
          <li onClick={() => (window.location.href = "#team")}>
            <a>{t("nav_03")}</a>
          </li>
          <li onClick={() => (window.location.href = "#vision")}>
            <a>{t("nav_04")}</a>
          </li>
          <li onClick={() => (window.location.href = "#download")}>
            <a>{t("nav_05")}</a>
          </li>
          <li onClick={() => (window.location.href = "#link")}>
            <a>{t("nav_06")}</a>
          </li>
        </ul>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <div
            className="lan_select"
            onClick={() => setShowOptions((prev) => !prev)}
          >
            <div
              style={{
                alignItems: "center",
                justifyContent: "space-between",
                display: "flex",
              }}
            >
              <label className="lan_select_label B12_M">{currentValue}</label>
              <img
                src={images.dropdown_narrow}
                style={{ width: 14, height: 19 }}
              />
            </div>
            <ul
              className="lan_select_options "
              style={showOptions ? { maxHeight: "none" } : { maxHeight: 0 }}
            >
              {languageData.map((itm, idx) => (
                <li
                  key={idx}
                  className="lan_select_option"
                  onClick={() => handleOnChangeSelectValue(itm)}
                >
                  {itm.title}
                </li>
              ))}
            </ul>
          </div>
          <button
            className="mobile-menu-icon"
            onClick={() => setIsMobile(!isMobile)}
          >
            {isMobile ? <FaTimes /> : <FaBars />}
          </button>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
